<template>
  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
    <loader v-if="!isContentLoaded" :is-visible="!isContentLoaded"></loader>
    <div v-if="isContentLoaded && chartOptions.colors.length > 0">
      <div class="iq-card-header d-flex justify-content-between">
        <apex-chart-header v-if="header" :disable-range-selection="true" :header="header" :header-icon="headerIcon"></apex-chart-header>
      </div>
      <div class="card-body iq-card-body p-1" style="position: relative;">
        <div id="chartUniqueId">
          <apexchart v-if="isContentLoaded"
                     type="pie"
                     height="300"
                     :options="chartOptions"
                     :series="series"></apexchart>
        </div>
      </div>
    </div>
  </iq-card>
</template>
<script>
    import Loader from "../../components/shared/Loader";
    import ApexChartHeader from "../../components/apexCharts/ApexChartHeader";

    import ChartService from "../../services/chart";
    import "../../plugins/apexchart";

    export default {
        components: {
          Loader,
          ApexChartHeader
        },
        props: {
            dataUrl: String,
            dataObject: Object,
            dateMin: String,
            dateMax: String
        },
        data() {
            return {
                date: { startDate: "", endDate: "" },
                isContentLoaded: false,
                header: "",
                headerIcon: "",
                series: [],
                chartOptions: {
                  colors: [],
                  chart: {
                    width: 380,
                    type: "pie",
                    zoom: {
                      enabled: false
                    },
                    toolbar: {
                      show: true,
                      offsetX: 0,
                      offsetY: 0,
                      tools: {
                        download: true,
                        selection: false,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: false,
                        reset: false
                      }
                    },
                    defaultLocale: 'tr',
                    locales: [{
                      name: 'tr',
                      options: {
                        toolbar: {
                          "exportToSVG": this.$t('DownloadSvg'),
                          "exportToPNG": this.$t('DownloadPng'),
                          "menu": this.$t('Menu'),
                          "selection": this.$t('Selection'),
                          "selectionZoom": this.$t('SelectionZoom'),
                          "zoomIn": this.$t('ZoomIn'),
                          "zoomOut": this.$t('zoomOut'),
                          "pan": this.$t('Panning'),
                          "reset": this.$t('ResetZoom')
                        }
                      }
                    }]
                  },
                  labels: [],
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200
                        },
                        legend: {
                          position: "bottom"
                        }
                      }
                    }
                  ]
                }
            };
        },
        methods: {
            getData() {
                ChartService.getChartData(this.computedUrl)
                    .then(response => {
                        if (response) {
                          this.setData(response);
                        }
                  });
            },
            async getColorList() {
                await ChartService.getColorsForApexChart()
                  .then((response) => {
                      this.chartOptions.colors = response;
                  });
            },
            setData(filledChartData) {
                let self = this;

                self.isContentLoaded = false;

                self.series = [];
                self.series = filledChartData.series;

                self.header = self.$t(filledChartData.chartHeader);
                self.headerIcon = filledChartData.chartHeaderIcon ?? "";

                if (filledChartData.colors != null) { self.chartOptions.colors = filledChartData.colors; }
                self.chartOptions.labels = [];
                if (filledChartData.labelTranslation) {
                    for (var i = 0; i < filledChartData.labels.length; i++) {
                        self.chartOptions.labels.push(self.$t(filledChartData.labels[i]));
                    }
                }
                else {
                    self.chartOptions.labels = filledChartData.labels.map(function (v) {
                        if (v == null)
                            v = '';
                        return v;
                    });
                }

                self.isContentLoaded = true;
            }
        },
        watch: {
            date: function () {
                this.getData();
            },
            computedUrl: {
                handler: function () {
                    if (this.computedUrl)
                        this.getData();
                },
                immediate: true
            },
            chartData: {
                handler: function () {
                    if (this.chartData)
                        this.setData(this.chartData);
                },
                immediate: true
            }
        },
        computed: {
            chartUniqueId: function () {
                return "apexPieChart";
            },
            computedUrl: function () {
                let $this = this;
                if ($this.dateMin && $this.dateMax) {
                    return $this.dataUrl + "?d1=" + $this.dateMin + "&d2=" + $this.dateMax;
                }
                else {
                    return $this.dataUrl;
                }
            },
            chartData: function () {
                return this.dataObject;
            }
        },
        mounted: function () {
            this.getColorList();
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep .apexcharts-menu-item.exportCSV {
        display: none;
    }

    ::v-deep .apexcharts-menu.apexcharts-menu-open {
        min-width: 82px !important;
    }
</style>
